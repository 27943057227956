import { Chip, Divider, styled } from "@mui/material";
import { Layout } from "../Components/Layout";
import { palette } from "../Styles/theme";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";
import { Size } from "../utils/types";

const TextWrapper = styled("div")(() => ({
  margin: "24px auto",
}));

const EventInfoText = styled("div")(() => ({
  margin: "24px auto",
}));

const Exhibitions = () => {
  const { renderText } = useTranslatedContent();

  return (
    <Layout size={Size.MID}>
      <div>
        <>
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.title",
            })}
            {renderText({
              key: "pages.exhibitions.description",
            })}
          </TextWrapper>
          <br />
          <Divider
            sx={{
              margin: "12px auto",
            }}
          >
            <Chip label="2024" size="small" />
          </Divider>
          <>
            <TextWrapper>
              {renderText({
                key: "pages.exhibitions.2024.one_twelve.title",
              })}
              {renderText({
                key: "pages.exhibitions.2024.one_twelve.subtitle",
              })}
              {renderText({
                key: "pages.exhibitions.2024.one_twelve.link",
                url: "https://www.instagram.com/one_twelve_gallery",
              })}
              <Divider
                sx={{
                  margin: "12px auto",
                }}
              />
              <EventInfoText>
                {renderText({
                  key: "pages.exhibitions.2024.one_twelve.info",
                })}
                <br />
                {renderText({
                  key: "pages.exhibitions.2024.one_twelve.address",
                })}
                <br />
                {renderText({
                  key: "pages.exhibitions.2024.one_twelve.description",
                })}
              </EventInfoText>
            </TextWrapper>
            <Divider
              variant="middle"
              sx={{
                borderColor: palette.secondary.main,
                maxWidth: "50%",
                textAlign: "center",
                margin: "24px auto",
              }}
            />
          </>
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.2024.sortir_del_blanc.title",
            })}
            {renderText({
              key: "pages.exhibitions.2024.sortir_del_blanc.subtitle",
            })}
            {renderText({
              key: "pages.exhibitions.2024.sortir_del_blanc.link",
              url: "https://tallerbalam.com/evento/sortir-del-blanc-exposicion-colectiva-collage/",
            })}
            <Divider
              sx={{
                margin: "12px auto",
              }}
            />
            <EventInfoText>
              {renderText({
                key: "pages.exhibitions.2024.sortir_del_blanc.info",
              })}
              <br />
              {renderText({
                key: "pages.exhibitions.2024.sortir_del_blanc.address",
              })}
              <br />
              {renderText({
                key: "pages.exhibitions.2024.sortir_del_blanc.activities",
              })}
              <br />
              {renderText({
                key: "pages.exhibitions.2024.sortir_del_blanc.description",
              })}
            </EventInfoText>
          </TextWrapper>
        </>
        <Divider
          variant="middle"
          sx={{
            borderColor: palette.secondary.main,
            maxWidth: "50%",
            textAlign: "center",
            margin: "24px auto",
          }}
        />
        <>
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.title",
            })}
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.subtitle",
            })}
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.link",
              url: "https://www.specialagentcollagecollective.com/collage-o-rama?mc_cid=e3b1e86cdf&mc_eid=60c6e07849J",
            })}
            <br />
            <Divider
              sx={{
                margin: "12px auto",
              }}
            />
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.info",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.address",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.activities",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.collage_o_rama.description",
            })}
          </TextWrapper>
        </>
        <Divider
          variant="middle"
          sx={{
            borderColor: palette.secondary.main,
            maxWidth: "50%",
            textAlign: "center",
            margin: "24px auto",
          }}
        />
        <>
          <TextWrapper>
            {renderText({
              key: "pages.exhibitions.2024.kolash.title",
            })}
            {renderText({
              key: "pages.exhibitions.2024.kolash.subtitle",
            })}
            {renderText({
              key: "pages.exhibitions.2024.kolash.link",
              url: "https://www.somoscomplices.com/",
            })}
            <br />
            <Divider
              sx={{
                margin: "12px auto",
              }}
            />
            <br />
            {renderText({
              key: "pages.exhibitions.2024.kolash.info",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.kolash.address",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.kolash.activities",
            })}
            <br />
            {renderText({
              key: "pages.exhibitions.2024.kolash.description",
            })}
          </TextWrapper>
        </>
      </div>
    </Layout>
  );
};

export default Exhibitions;
